<template>
  <v-card
    flat
    class="pa-0 mt-2"
  >
    <v-card-title class="flex-nowrap">
      <v-icon class="text--primary me-3">
        {{ icons.mdiHome }}
      </v-icon>
      <span class="text-break">Tenant Settings</span>
    </v-card-title>

    <v-card-text>
      <v-form
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            v-show="mode === 'update'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="tenantData.id"
              label="Tenant ID (read-only)"
              :append-icon="icons.mdiContentCopy"
              dense
              outlined
              :disabled="false"
              :readonly="true"
              @click:append="idToClipboard"
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="tenantData.label"
              hide-details="auto"
              label="Label"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            v-if="mode === 'update'"
            cols="12"
            md="6"
          >
            <v-select
              v-model="tenantData.status"
              dense
              outlined
              required
              label="Status *"
              :items="status"
            ></v-select>
          </v-col>

          <v-col
            v-show="mode === 'update'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="tenantData.account"
              label="Tenant Owner"
              dense
              outlined
              :disabled="true"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-show="uiMode === 'account'">
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="tenantData.subscription"
              dense
              outlined
              label="Subscription *"
              required
              :items="subscriptions"
              item-text="combined_label"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col
            v-show="mode === 'update'"
            cols="12"
            md="6"
          >
            <strong>Note</strong>: Changing the subscription on this tenant will result in all metrics being charged against the new subscription from the next month on.
          </v-col>
        </v-row>

        <v-row v-if="tenantData.config && mode === 'update' && uiMode === 'admin'">
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="tenantData.config.tokens.login.exp"
              type="text"
              outlined
              required
              :rules="loginSessionExpRule"
              dense
              label="Login Session Expiration (max. 1y)"
              placeholder="Use value and s(ecs) / m(ins) / h(rs) / d(ays) /w(eeks) /y(rs)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="tenantData.config.tokens.signup.exp"
              type="text"
              outlined
              required
              :rules="signupSessionExpRule"
              dense
              label="Signup Session Expiration (max. 1h)"
              placeholder="Use value and s(ecs) / m(ins) / h(rs)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="tenantData.config.accounts.enabled.exp"
              type="text"
              outlined
              required
              :rules="inactiveAccountExpRule"
              dense
              label="Inactive Account Expiration (max. 400d)"
              placeholder="Use value and s(ecs) / m(ins) / h(rs)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="tenantData.config.accounts.pending.exp"
              type="text"
              outlined
              required
              :rules="pendingAccountExpRule"
              dense
              label="Pending Account Expiration (max. 30d)"
              placeholder="Use value and s(ecs) / m(ins) / h(rs) / d(ays) /w(eeks)"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <div style="margin-bottom:10px">
              Color Code:
            </div>
            <v-color-picker
              v-model="color"
              type="hex"
              mode="hexa"
            ></v-color-picker>
            <div><small>You can color-code a tenant to easily distinguish<br />it from others in the Tenant Admin UI.</small></div>
          </v-col>
          <v-col
            v-if="mode === 'update'"
            cols="12"
            md="6"
          >
            <v-text-field
              :value="tenantAdminUrl()"
              label="Tenant Admin URL (read-only)"
              :append-icon="icons.mdiContentCopy"
              dense
              outlined
              :disabled="false"
              :readonly="true"
              @click:append="adminUrlToClipboard"
            ></v-text-field>
            <v-btn
              v-if="uiMode === 'account'"
              small
              color="primary"
              class="success me-3 mt-4"
              @click="openTenantAdminUI()"
            >
              Open Tenant Admin
            </v-btn>
          </v-col>
        </v-row>

        <!--
        <v-row v-if="uiMode === 'admin'">
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="permissionControls"
              outlined
              dense
              multiple
              chips
              xsmall-chips
              disabled
              label="Tenant-Wide Permissions (read-only)"
              :items="controls"
              item-text="label"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="consentControls"
              outlined
              dense
              multiple
              chips
              xsmall-chips
              disabled
              label="Tenant-Wide Consents (read-only)"
              :items="controls"
              item-text="label"
              item-value="id"
            ></v-select>
          </v-col>
        </v-row>
        -->
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiAlertOutline, mdiCloudUploadOutline, mdiEyeOffOutline, mdiEyeOutline, mdiFormSelect, mdiHome, mdiContentCopy,
} from '@mdi/js'
import * as QuasrHelper from '@quasr-io/helper'

export default {

  /**
   * props
   */
  props: {

    /**
     * tenantData
     */
    tenantData: {
      type: Object,
      default: () => {},
    },

    tenantColor: {
      type: String,
      default: '',
    },

    /**
     * subscriptions
     */
    subscriptions: {
      type: Array[Object],
      default: () => [],
    },

    /**
     * consent_controls
     */
    consentControls: {
      type: Array[Object],
      default: () => [],
    },

    /**
     * permission_controls
     */
    permissionControls: {
      type: Array[Object],
      default: () => [],
    },

    /**
     * scopes
     */
    controls: {
      type: Array[Object],
      default: () => [],
    },

    /**
     * mode
     */
    mode: { type: String, default: undefined },
  },

  /**
   * data
   */
  data() {
    return {
      tenantColorLocal: this.tenantColor,
      inputFieldRules: [
        value => !!value || 'Required',
      ],
      requiredRule: [
        value => !!value || 'Required',
      ],
      loginSessionExpRule: [
        value => (!Number.isNaN(parseInt(QuasrHelper.convertStringToSeconds(value, true), 10)) && QuasrHelper.convertStringToSeconds(value, true) <= QuasrHelper.EXPIRATION_LIMITS.login_session_expiration) || 'Required and max. 1y',
      ],
      signupSessionExpRule: [
        value => (!Number.isNaN(parseInt(QuasrHelper.convertStringToSeconds(value, true), 10)) && QuasrHelper.convertStringToSeconds(value, true) <= QuasrHelper.EXPIRATION_LIMITS.signup_session_expiration) || 'Required and max. 1h',
      ],
      inactiveAccountExpRule: [
        value => (!Number.isNaN(parseInt(QuasrHelper.convertStringToSeconds(value, true), 10)) && QuasrHelper.convertStringToSeconds(value, true) <= QuasrHelper.EXPIRATION_LIMITS.inactive_account_expiration) || 'Required and max. 400d',
      ],
      pendingAccountExpRule: [
        value => (!Number.isNaN(parseInt(QuasrHelper.convertStringToSeconds(value, true), 10)) && QuasrHelper.convertStringToSeconds(value, true) <= 2592000) || 'Required and max. 30d',
      ],
      type: 'hex',
      hex: this.tenantColor,
      hexa: `${this.tenantColor}`,
    }
  },

  computed: {
    subscriptionLabel(item) {
      return item.metadata.nickname.concat(' ').concat(item.id)
    },
    color: {
      get() {
        return this.tenantColorLocal
      },
      set(v) {
        this.tenantColorLocal = v
      },
    },
    showColor() {
      if (typeof this.color === 'string') return this.color

      return JSON.stringify(Object.keys(this.color).reduce((color, key) => {
        this.color[key] = Number(this.color[key].toFixed(2))

        return color
      }, {}), null, 2)
    },
  },

  /**
   * watch
   */
  watch: {
    /**
     * tenantColorLocal
     */
    tenantColorLocal() {
      this.$emit('tenant-color-change-event', this.tenantColorLocal)
    },

    /**
     * tenantColor
     */
    tenantColor() {
      this.tenantColorLocal = this.tenantColor
    },
  },

  /**
   * methods
   */
  methods: {

    /**
     *
     */
    openTenantAdminUI() {
      window.open(this.tenantAdminUrl())
    },

    /**
     * df
     */
    df(isoString) {
      return new Date(isoString).toLocaleString()
    },

    /**
     * cancel
     */
    cancel() {
      this.$router.back()
    },

    /**
     * idToClipboard
     */
    idToClipboard() {
      QuasrHelper.copyTextToClipboard(this.tenantData.id)
    },

    /**
     * adminUrlToClipboard
     */
    adminUrlToClipboard() {
      QuasrHelper.copyTextToClipboard(this.tenantAdminUrl())
    },

    /**
     * tenantAdminUrl
     */
    tenantAdminUrl() {
      if (this.tenantData?.admin_client) {
        const origin = document.location.origin.replace('account', 'admin')

        return `${origin}/${this.tenantData.id}/${this.tenantData.admin_client}`
      }

      return undefined
    },
  },

  /**
   * setup
   */
  setup() {
    const ENV = QuasrHelper.getEnv()
    const uiMode = document.location.hostname.startsWith('account') ? 'account' : 'admin'
    const status = [
      { text: 'Enabled', value: 'ENABLED' },
      { text: 'Disabled', value: 'DISABLED' },
    ]

    return {
      ENV,
      uiMode,
      status,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiFormSelect,
        mdiHome,
        mdiContentCopy,
      },
    }
  },
}
</script>
