<template>
  <v-card id="tenant-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <v-col
      cols="12"
      style="padding-bottom:0px; margin-bottom:0px"
    >
      <v-alert
        v-show="alert.show"
        :color="alert.color"
        text
        class="mb-0"
      >
        <div class="d-flex align-start">
          <v-icon :color="alert.color">
            {{ icons.mdiAlertOutline }}
          </v-icon>

          <div class="ms-3">
            <p class="text-base font-weight-medium mb-1 justify-right">
              {{ alert.message }}
            </p>
            <a
              align="right"
              href="javascript:void(0)"
              title="Hide this message"
              :class="alert.class"
              @click.prevent="hideAlert"
            >
              <span class="text-sm">{{ alert.callToAction }}</span>
            </a>
          </div>
        </div>
      </v-alert>
    </v-col>

    <!-- loading icon -->
    <div
      v-show="isLoading"
      id="misc"
    >
      <div class="page-title text-center justify-center px-5">
        <div class="misc-character d-flex justify-center">
          <v-img
            max-width="100"
            margin="100"
            src="@/assets/images/misc/loading-spinner-dark.gif"
          ></v-img>
        </div>
      </div>
    </div>

    <!-- tabs item -->
    <v-tabs-items
      v-show="!isLoading"
      v-model="tab"
    >
      <v-tab-item>
        <tenant-settings-general
          :tenant-data="tenantData"
          :subscriptions="subscriptions"
          :controls="controls"
          :consent-controls="consent_controls"
          :permission-controls="permission_controls"
          :mode="mode"
          :tenant-color="computedTenantColor"
          @tenant-color-change-event="tenantColorChangeHandler"
        ></tenant-settings-general>
      </v-tab-item>
    </v-tabs-items>

    <v-card
      flat
      class="pa-0 mt-2"
    >
      <v-card-text>
        <v-form class="multi-col-validation mt-6">
          <v-row>
            <v-col cols="6">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                :disabled="!isFormValid || isLoading"
                @click="saveTenant"
              >
                {{ uiMode === 'account' ? 'Save & Exit' : 'Save' }}
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                :disabled="isLoading"
                @click.prevent="exit"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col
              v-show="uiMode === 'account'"
              cols="6"
              class="text-right"
            >
              <v-btn
                color="error"
                outlined
                class="me-3 mt-4"
                type="reset"
                :disabled="!tenantData.id || tenantData.id==QUASR_ROOT_TENANT || isLoading"
                @click="deleteItem"
              >
                Delete
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h6">
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            text
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-btn
            color="blue darken-1"
            outlined
            text
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline, mdiRobotOutline,
  mdiFingerprint, mdiWavesArrowRight, mdiAutoFix, mdiFormSelect, mdiBadgeAccountOutline,
  mdiBadgeAccountAlertOutline, mdiProtocol, mdiLockOutline, mdiKeyChain, mdiHome,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import * as QuasrHelper from '@quasr-io/helper'
import gql from 'graphql-tag'
import { getTenant } from '../../graphql/queries'
import { createTenant, updateTenant, deleteTenant } from '../../graphql/mutations'
import TenantSettingsGeneral from './TenantSettingsGeneral.vue'
import store from '../../store'

export default {

  /**
   * components
   */
  components: {
    TenantSettingsGeneral,
  },

  /**
   * data
   */
  data() {
    if (!this.loginStatus.logged_in) {
      this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/`)
    }

    return {
      mode: this.$route.params.id === 'new' ? 'create' : 'update',
      alert: {
        show: false,
        message: '',
        callToAction: 'OK',
        color: 'success',
        class: 'text-decoration-none success--text pointer',
      },
      subscriptions: [],
      controls: [],
      consents: [],
      permissions: [],
      consent_controls: [],
      permission_controls: [],
      dialog: false,
      dialogDelete: false,
      tenantData: {
        type: 'tenant',
        account: this.loginStatus.sub,
        status: 'ENABLED',
        config: {
          color: '#444444',
          tokens: {
            login: {
              exp: '1d',
            },
            signup: {
              exp: '15m',
            },
          },
          accounts: {
            pending: {
              exp: '1d',
            },
            enabled: {
              exp: '90d',
            },
          },
        },
      },
    }
  },

  /**
   * computed
   */
  computed: {

    computedTenantColor() {
      return this.tenantData?.config?.color
    },

    /**
     * isFormValid
     */
    isFormValid() {
      const missingFields = []
      if (this.mode === 'update' && !this.tenantData.id) {
        missingFields.push('ID')
      }
      if (!this.tenantData.subscription) {
        missingFields.push('Subscription')
      }
      if (this.tenantData?.config?.color && !/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(this.tenantData?.config?.color)) {
        missingFields.push('Invalid color code')
      }
      if (!this.isValidExpiration('login_session_expiration')) {
        missingFields.push('Invalid login session expiration')
      }
      if (!this.isValidExpiration('signup_session_expiration')) {
        missingFields.push('Invalid signup session expiration')
      }
      if (!this.isValidExpiration('pending_account_expiration')) {
        missingFields.push('Invalid pending account expiration')
      }
      if (!this.isValidExpiration('inactive_account_expiration')) {
        missingFields.push('Invalid inactive account expiration')
      }
      QuasrHelper.log('Missing fields: '.concat(JSON.stringify(missingFields)))

      return missingFields.length === 0
    },
  },

  /**
   * created
   */
  async created() {
    if (!this.loginStatus.logged_in) this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/`)
    this.isLoading = true
    await this.getTenant()
    this.isLoading = false
  },

  /**
   * methods
   */
  methods: {

    /**
     * isValidExpiration
     */
    isValidExpiration(fieldName) {
      let fieldValue
      switch (fieldName) {
        case 'login_session_expiration':
          fieldValue = this.tenantData.config.tokens.login.exp
          break
        case 'signup_session_expiration':
          fieldValue = this.tenantData.config.tokens.signup.exp
          break
        case 'pending_account_expiration':
          fieldValue = this.tenantData.config.accounts.pending.exp
          break
        case 'inactive_account_expiration':
          fieldValue = this.tenantData.config.accounts.enabled.exp
          break
        default:
          return false
      }

      const seconds = fieldValue ? QuasrHelper.convertStringToSeconds(fieldValue, true) : undefined
      const max = fieldName !== 'pending_account_expiration' ? QuasrHelper.EXPIRATION_LIMITS[fieldName] : 2592000

      return !(!parseInt(seconds, 10) || !max || !seconds || seconds < 0 || seconds > max)
    },

    /**
     * tenantColorChangeHandler
     */
    tenantColorChangeHandler(val) {
      this.tenantData.config.color = val
    },

    /**
     * getTenant
     */
    async getTenant() {
      if (this.mode === 'update' && this.$route.params.id !== 'new') {
        try {
          const tenant = await store.getAppSyncClient().query({ query: gql(getTenant), variables: { id: this.$route.params.id } })
          this.tenantData = tenant.data.getTenant
          this.tenantColor = this.tenantData.config?.color || '#444444'
        } catch (err) {
          QuasrHelper.log(err)
          const e = JSON.parse(JSON.stringify(err))
          if (e.status === 401 || e.networkError?.statusCode === 401) {
            localStorage.removeItem(`${this.QUASR_ENV.tenant_id}.exp`)
            this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/login`)
          } else {
            this.exit()
          }
        }
      }
    },

    /**
     * showAlert
     */
    showAlert(message, callToAction, color) {
      this.alert.message = message
      this.alert.callToAction = callToAction
      this.alert.show = true
      this.alert.color = color
      this.alert.class = 'text-decoration-none '.concat(color).concat('--text')
    },

    /**
     * hideAlert
     */
    hideAlert() {
      this.alert.show = false
    },

    /**
     * exit
     */
    exit() {
      this.$router.back()
    },

    /**
     * closeDelete
     */
    closeDelete() {
      this.dialogDelete = false
    },

    /**
     * deleteItem
     */
    deleteItem() {
      this.dialogDelete = true
    },

    /**
     * deleteItemConfirm
     */
    async deleteItemConfirm() {
      await this.deleteTenant()
      this.closeDelete()
    },

    /**
     * deleteTenant
     */
    async deleteTenant() {
      try {
        await store.getAppSyncClient().mutate({ mutation: gql(deleteTenant), variables: { input: { id: this.tenantData.id } } })

        // if we're deleting the current tenant, we log the user out afterwards, otherwise
        // just return to previous screen
        if (this.loginStatus.ten === this.tenantData.id) {
          this.logout()
        } else {
          this.exit()
        }
      } catch (err) {
        QuasrHelper.log(err)
        const e = JSON.parse(JSON.stringify(err))
        if (e.status === 401 || e.networkError?.statusCode === 401) {
          localStorage.removeItem(`${this.QUASR_ENV.tenant_id}.exp`)
          this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/login`)
        }
      }
    },

    /**
     * saveTenant
     */
    async saveTenant() {
      this.isLoading = true
      const obj = {
        label: this.tenantData.label,
        status: this.tenantData.status,
        config: {
          color: this.tenantData?.config?.color,
          signup_session_expiration: this.tenantData?.config?.signup_session_expiration,
          login_session_expiration: this.tenantData?.config?.login_session_expiration,
          inactive_tenant_expiration: this.tenantData?.config?.inactive_tenant_expiration,
          inactive_account_expiration: this.tenantData?.config?.inactive_account_expiration,
        },
      }
      if (this.uiMode === 'account') {
        obj.subscription = this.tenantData.subscription
      }
      obj.config.color = this.tenantData?.config?.color

      // ==== CREATE ===
      if (this.mode === 'create') {
        try {
          const cmd = await store.getAppSyncClient().mutate({ mutation: gql(createTenant), variables: { input: obj } })
          if (cmd.data.createTenant) {
            this.tenantData = cmd.data.createTenant
            this.mode = 'update'
            this.showAlert('Tenant saved successfully.', 'OK', 'success')
            this.$eventHub.$emit('tenant-color-change-event', this.tenantData.config.color)
            this.$eventHub.$emit('tenant-label-change-event', this.tenantData.label)
            if (this.uiMode === 'account') {
              this.exit()
            } else {
              this.isLoading = false
            }
          } else {
            this.showAlert('Saving tenant failed.', 'OK', 'error')
            this.isLoading = false
          }
        } catch (err) {
          const e = JSON.parse(JSON.stringify(err))
          if (e.status === 401 || e.networkError?.statusCode === 401) {
            localStorage.removeItem(`${this.QUASR_ENV.tenant_id}.exp`)
            this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/login`)
          } else {
            this.showAlert(err, 'OK', 'error')
            this.isLoading = false
          }
        }
      } else

      // ==== UPDATE ===
      if (this.mode === 'update') {
        obj.id = this.tenantData.id
        obj.config = this.tenantData.config
        const cmd = await store.getAppSyncClient().mutate({ mutation: gql(updateTenant), variables: { input: obj } })
        if (cmd.data.updateTenant) {
          this.showAlert('Tenant saved successfully.', 'OK', 'success')
          this.$eventHub.$emit('tenant-color-change-event', this.tenantData.config.color)
          this.$eventHub.$emit('tenant-label-change-event', this.tenantData.label)
          if (this.uiMode === 'account') {
            this.exit()
          } else {
            this.isLoading = false
          }
        } else {
          this.showAlert('Saving tenant failed.', 'OK', 'error')
          this.isLoading = false
        }
      }
    },

    /**
     * logout
     */
    logout() {
      localStorage.clear()
      this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/logout`)
    },
  },

  /**
   * setup
   */
  setup() {
    const ENV = QuasrHelper.getEnv()
    const API_ENV = QuasrHelper.getApiEnv()
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    // eslint-disable-next-line
    const QUASR_ROOT_TENANT = QuasrHelper.ID.QUASR_ROOT_TENANT
    const uiMode = document.location.hostname.startsWith('account') ? 'account' : 'admin'
    const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
    const tab = ref('')
    const isLoading = true

    // tabs
    const tabs = [
      { title: 'Tenant Settings', icon: mdiHome },
    ]

    return {
      ENV,
      API_ENV,
      QUASR_ENV,
      QUASR_ROOT_TENANT,
      uiMode,
      isLoading,
      loginStatus,
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiRobotOutline,
        mdiFingerprint,
        mdiBadgeAccountAlertOutline,
        mdiBadgeAccountOutline,
        mdiWavesArrowRight,
        mdiAutoFix,
        mdiFormSelect,
        mdiProtocol,
        mdiLockOutline,
        mdiKeyChain,
        mdiHome,
      },
    }
  },
}
</script>
